import React from 'react';
import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import App from './App';

import '@quality24/design-system/src/styles/global.scss';
import './index.scss';

pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdn.quality24.com.br/res/pdf.worker.mjs';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
