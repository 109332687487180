import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Alert, Spinner } from '@quality24/design-system';
import { Document, Page } from 'react-pdf';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import styles from './ViewerPage.module.scss';

/**
 * <ViewerPage> component.
 */
const ViewerPage: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const [pdfError, setPdfError] = React.useState(false);
  const [totalPages, setTotalPages] = React.useState<number | null>(null);

  const pdfUrl = searchParams?.get('url');

  if (!pdfUrl) {
    return (
      <div className={styles.errorWrapper}>
        <Alert variant="danger">É necessário passar a url do PDF.</Alert>
      </div>
    );
  }

  if (pdfError) {
    return (
      <div className={styles.errorWrapper}>
        <Alert variant="danger">Erro ao carregar o PDF.</Alert>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <TransformWrapper
        wheel={{ wheelDisabled: true }}
        panning={{ disabled: true }}
        pinch={{ step: 1 }}
      >
        <TransformComponent>
          <Document
            file={pdfUrl}
            loading={
              <div className="p-4 mt-4">
                <Spinner />
              </div>
            }
            onSourceError={() => setPdfError(true)}
            onLoadError={() => setPdfError(true)}
            onLoadSuccess={({ numPages }) => {
              setTotalPages(numPages);
            }}
          >
            {Array.from(new Array(totalPages), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                className={styles.page}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            ))}
          </Document>
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};

export default ViewerPage;
