import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ViewerPage from '../pages/ViewerPage';

/**
 * <PublicRouter> component.
 */
const PublicRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/viewer" element={<ViewerPage />} />
      <Route path="*" element={<Navigate to="/viewer" replace />} />
    </Routes>
  );
};

export default PublicRouter;
